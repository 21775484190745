<template>
  <div style="width: 100%" class="overflow-hidden flex-1 overflow-hidden">
    <v-form
      v-if="!this.registerComplete"
      ref="mainForm"
      autocomplete="new-password"
    >
      <v-container class="wrapper mb-16">
        <h2 class="my-4">Asiakastiedot</h2>
        <v-row>
          <v-col cols="12" md="6" class="pb-0">
            <v-text-field
              outlined
              dense
              v-model="formData.firstName"
              label="Etunimi"
              :rules="mandatoryFieldRules"
              required
              autocomplete="new-password"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="pb-0">
            <v-text-field
              outlined
              dense
              v-model="formData.lastName"
              label="Sukunimi"
              :rules="mandatoryFieldRules"
              required
              autocomplete="new-password"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-8">
          <v-col cols="12" md="6" class="py-0">
            <v-text-field
              outlined
              dense
              v-model="formData.email"
              :rules="emailRules"
              label="Sähköpostiosoite"
              required
              autocomplete="new-password"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="py-0">
            <v-text-field
              outlined
              dense
              v-model="formData.contactInformation.phoneNumber"
              label="Puhelinnumero"
              :rules="mandatoryFieldRules"
              required
              autocomplete="new-password"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-form ref="passwordForm">
          <h2 class="mb-4">Salasana</h2>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                type="password"
                dense
                v-model="newPassword"
                :rules="passwordRules"
                label="Uusi salasana"
                required
                autocomplete="new-password"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                outlined
                type="password"
                dense
                v-model="newPasswordAgain"
                :rules="passwordAgainRules"
                label="Vahvista salasana"
                required
                autocomplete="new-password"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <h2 class="my-4">Osoitetiedot</h2>
        <v-row>
          <v-col cols="12" md="6" class="pb-0">
            <v-text-field
              outlined
              dense
              v-model="formData.address.addressLine1"
              label="Katuosoite"
              :rules="mandatoryFieldRules"
              required
              autocomplete="new-password"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="pb-0">
            <v-text-field
              outlined
              dense
              v-model="formData.address.postalCode"
              label="Postinumero"
              :rules="mandatoryFieldRules"
              required
              autocomplete="new-password"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field
              outlined
              dense
              v-model="formData.address.city"
              label="Postitoimipaikka"
              :rules="mandatoryFieldRules"
              required
              autocomplete="new-password"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="py-0">
            <v-text-field
              outlined
              dense
              v-model="formData.address.country"
              label="Maa"
              :rules="mandatoryFieldRules"
              autocomplete="new-password"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-8">
          <v-col cols="12" md="12" class="py-0">
            <v-text-field
              outlined
              dense
              v-model="formData.address.additionalDetails"
              label="Ovikoodi tai muu lisätieto"
              autocomplete="new-password"
            ></v-text-field>
            <v-btn
              v-if="!showDeliveryFields"
              color="primary"
              class="mx-0 mb-4"
              outlined
              rounded
              large
              @click="toggleDeliveryFields"
              autocomplete="new-password"
            >
              <span class="d-sm-inline mr-2">Lisää toimitusosoite</span>
            </v-btn>
          </v-col>
        </v-row>
        <div v-if="showDeliveryFields">
          <h2 class="my-4">Toimitusosoite</h2>
          <v-row>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                outlined
                dense
                v-model="formData.deliveryAddress.addressLine1"
                label="Katuosoite"
                :rules="mandatoryFieldRules"
                required
                autocomplete="new-password"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                outlined
                dense
                v-model="formData.deliveryAddress.postalCode"
                label="Postinumero"
                :rules="mandatoryFieldRules"
                required
                autocomplete="new-password"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                outlined
                dense
                v-model="formData.deliveryAddress.city"
                label="Postitoimipaikka"
                :rules="mandatoryFieldRules"
                required
                autocomplete="new-password"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                outlined
                dense
                v-model="formData.deliveryAddress.country"
                label="Maa"
                :rules="mandatoryFieldRules"
                autocomplete="new-password"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-8">
            <v-col cols="12" md="12" class="py-0">
              <v-text-field
                outlined
                dense
                v-model="formData.deliveryAddress.additionalDetails"
                label="Ovikoodi tai muu lisätieto"
              ></v-text-field>
              <v-btn
                v-if="showDeliveryFields"
                color="primary"
                class="mx-0 mb-4"
                outlined
                rounded
                large
                @click="toggleDeliveryFields"
                autocomplete="new-password"
              >
                <span class="d-sm-inline mr-2"
                  >Poista toimitusosoite</span
                >
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <h2 class="my-4">Yritysasiakkaat</h2>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              dense
              v-model="formData.contactInformation.companyName"
              label="Yrityksen nimi"
              required
              autocomplete="new-password"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              outlined
              dense
              v-model="formData.contactInformation.businessNumber"
              label="Y-tunnus"
              required
              autocomplete="new-password"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-btn color="primary" rounded @click="submitMainForm" block large
              >Luo asiakastili</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-card
      outlined
      tile
      v-if="this.registerComplete"
      class="pa-12"
      style="max-width: 650px; margin: 0 auto"
    >
      <h1 style="font-size: 1.5rem">Kiitos rekisteröitymisestä!</h1>
      <p class="my-4">
        Olemme lähettäneet sähköpostiisi linkin, jota klikkaamalla voit
        aktivoida tilisi.
      </p>
      <v-btn link color="primary" rounded to="/login" large
        >Kirjaudu sisään</v-btn
      >
    </v-card>
  </div>
</template>
<style scoped>
h2 {
  font-weight: 300;
}

.wrapper {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}
</style>

<script>
export default {
  data() {
    return {
      // formData: {},
      formData: {
        address: {
          additionalDetails: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          country: "",
          postalCode: "",
          province: "",
          adressLine2: "",
          adressline1: "",
        },
        contactInformation: {
          businessNumber: "",
          companyName: "",
          emailAddress: "",
          name: "",
          phoneNumber: "",
        },
        deliveryAddress: {
          additionalDetails: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          country: "",
          postalCode: "",
          province: "",
          adressLine2: "",
          adressline1: "",
        },
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        firstNameOptional: "",
        lastNameOptional: "",
      },

      firstName: "",
      lastName: "",
      inUseEmail: "",
      mandatoryFieldRules: [(v) => !!v || "Kenttä ei voi olla tyhjä."],
      email: "",
      emailRules: [
        (v) => !!v || "Sähköposti ei voi olla tyhjä.",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Sähköposti on virheellinen.",
        (v) => this.inUseEmail !== v || "Sähköposti jo käytössä",
      ],
      phoneNumber: "",
      streetAddress: "",
      postalCode: "",
      city: "",
      country: "",
      additionalInfo: "",
      deliveryStreetAddress: "",
      deliveryPostalCode: "",
      deliveryCity: "",
      deliveryCountry: "",
      deliveryAdditionalInfo: "",
      companyName: "",
      yTunnus: "",
      newPassword: "",
      newPasswordAgain: "",
      passwordRules: [
        (v) => !!v || "Kenttä ei voi olla tyhjä.",
        (v) => v.length >= 8 || "Vähintään 8 merkkiä",
      ],
      passwordAgainRules: [
        (v) => !!v || "Kenttä ei voi olla tyhjä.",
        (v) => v == this.newPassword || "Salasanat eivät täsmää",
      ],
      showDeliveryFields: false,
      url: process.env.VUE_APP_BBSHOP_ENDPOINT + "/register/",
      registerComplete: false,
    };
  },
  methods: {
    validatePhone({ isValid }) {
      this.phoneIsValid = isValid;
    },
    toggleDeliveryFields() {
      this.showDeliveryFields = !this.showDeliveryFields;
    },
    submitMainForm() {
      if (
        this.$refs.mainForm.validate() &&
        this.$refs.passwordForm.validate()
      ) {
        // console.log("all data valid");
        // this.formData["password"] = this.newPassword;
        // this.formData["email"] = this.formData.contactInformation.emailAddress;
        // this.formData["contactInformation"]["name"] =
        // this.formData.firstName + " " + this.formData.lastName;
        this.$set(this.formData, "password", this.newPassword);
        this.$set(
          this.formData.contactInformation,
          "emailAddress",
          this.formData.email
        );
        this.$set(
          this.formData.contactInformation,
          "name",
          this.formData.firstName + " " + this.formData.lastName
        );
        // console.log(this.formData);

        // let data = {
        //   address: {
        //     addressLine1: this.formData.addressLine1,
        //     city: this.formData.city,
        //     postalCode: this.formData.postalCode,
        //     country: this.formData.country,
        //   },
        //   email: this.formData.email,
        //   firstName: this.formData.firstName,
        //   lastName: this.formData.lastName,
        //   password: this.newPassword,
        // };

        this.axios
          .post(this.url, this.formData)
          .then((response) => {
            console.log(response);
            this.registerComplete = true;
          })
          .catch((err) => {
            this.$store.commit("setSnack", {
              text: "Email on jo käytössä",
              message: "",
              color: "error",
            });
            this.inUseEmail = this.formData.email;
            this.$refs.mainForm.validate();
            console.log(err);
          });

      }
    },
  },
};
</script>